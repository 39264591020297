<template>
    <div class="wrapper">
        <Header :code="projectCode" :title="companyName + projectName" :banner="headerBanner"></Header>
        <div class="content narrow">
            <div class="main-content">
                <div class="breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem @click.native="goProjectHome">招聘职位</BreadcrumbItem>
                        <BreadcrumbItem @click.native="goJobDetail">职位详情</BreadcrumbItem>
                        <BreadcrumbItem>报名申请</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="apply-job">
                    <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="120">
                        <div class="divider">职位信息</div>
                        <div class="apply-job-section">
                            <Row :gutter="16">
                                <Col span="12">
                                    <FormItem label="职位">
                                        <div>{{addForm.positionName}}</div>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="部门">
                                        <div>{{addForm.deptName}}</div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row :gutter="16">
                                <Col span="24">
                                    <FormItem label="信息来源" prop="position_1" :rules="itemIsRequired('position_1') ? addFormRule.selectRequired : null" v-if="itemIsVisible('position_1')">
                                        <Select v-model="addForm.position_1" filterable clearable>
                                            <Option v-for="item in position_1Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                        <div class="divider" v-if="categoryIsVisible('个人资料')">个人资料</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('个人资料')">
                            <Row :gutter="16">
                                <Col span="24">
                                    <FormItem label="照片" prop="person_1" :rules="[{required: itemIsRequired('person_1'), message: '请上传照片', trigger: 'change'}]" v-if="itemIsVisible('person_1')">
                                        <Upload ref="upload" type="drag"
                                                :headers="header"
                                                :max-size="5 * 1024"
                                                :action="uploadFiles"
                                                :show-upload-list="false"
                                                :format="['jpg', 'png', 'JPG', 'JPEG']"
                                                accept=".jpg,.png,.JPG,.JPEG"
                                                :before-upload="handleBeforeUpload"
                                                :on-exceeded-size="handExceeded"
                                                :on-success="handleSuccess"
                                                v-if="imgUrl == '' || imgUrl == null">
                                            <div class="upload-btn-l">
                                                <Icon type="md-cloud-upload"></Icon>
                                                <p class="tips">要求：一寸蓝底或白底照片</p>
                                            </div>
                                        </Upload>
                                        <div class="upload-image-list" v-else>
                                            <img :src="imgUrl"/>
                                            <div class="upload-image-list-cover">
                                                <Icon type="ios-eye" @click.native="handleImgPreview"></Icon>
                                                <Icon type="ios-trash" @click.native="handleRemove"></Icon>
                                            </div>
                                        </div>
                                    </FormItem>
                                    <Modal title="查看图片" v-model="showPreview" footer-hide :width="640">
                                        <img :src="imgUrl" v-if="showPreview" style="width: 100%"/>
                                    </Modal>
                                    <FormItem label="姓名" prop="person_2" :rules="[{required: itemIsRequired('person_2'), message: '请输入姓名', trigger: 'blur'}]" v-if="itemIsVisible('person_2')">
                                        <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.person_2" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="曾用名" prop="person_3" :rules="[{required: itemIsRequired('person_3'), message: '请输入曾用名', trigger: 'blur'}]" v-if="itemIsVisible('person_3')">
                                        <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.person_3" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="性别" prop="person_4" :rules="itemIsRequired('person_4') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_4')">
                                        <RadioGroup v-model="addForm.person_4">
                                            <Radio label="男">男</Radio>
                                            <Radio label="女">女</Radio>
                                        </RadioGroup>
                                    </FormItem>
                                    <FormItem label="出生年月" prop="person_5" :rules="itemIsRequired('person_5') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_5')">
                                        <DatePicker type="date" placeholder="请选择" v-model="addForm.person_5" :options="disabledOptions"></DatePicker>
                                    </FormItem>
                                    <FormItem label="籍贯" prop="person_6" :rules="itemIsRequired('person_6') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_6')">
                                        <treeselect v-model="addForm.person_6"
                                                    :max-height="130"
                                                    :options="countyOpts"
                                                    :disable-branch-nodes="true"
                                                    placeholder="请选择"
                                                    loadingText="加载中..."
                                                    noOptionsText="暂无数据"
                                                    noResultsText="没有匹配数据"
                                                    @select="handlePerson_6Selected">
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.full_label }}</div>
                                        </treeselect>
                                    </FormItem>
                                    <FormItem label="民族" prop="person_7" :rules="itemIsRequired('person_7') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_7')">
                                        <Select v-model="addForm.person_7" filterable clearable>
                                            <Option v-for="item in person_7Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="职称及职业资格" prop="person_8" :rules="itemIsRequired('person_8') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_8')">
                                        <Select v-model="addForm.person_8" multiple filterable clearable>
                                            <Option v-for="item in person_8Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="政治面貌" prop="person_9" :rules="itemIsRequired('person_9') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_9')">
                                        <Select v-model="addForm.person_9" filterable clearable @on-change="handlePerson9Change">
                                            <Option v-for="item in person_9Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="入党时间" prop="person_10" :rules="itemIsRequired('person_10') ? addFormRule.selectRequired : null" v-if="addForm.person_9 === 202703 && itemIsVisible('person_10')">
                                        <DatePicker type="date" placeholder="请选择" v-model="addForm.person_10" :options="disabledOptions"></DatePicker>
                                    </FormItem>
                                    <FormItem label="身高(cm)" prop="person_11" :rules="[{required: itemIsRequired('person_11'), message: '请输入身高(cm)', type: 'number', trigger: 'blur'}]" v-if="itemIsVisible('person_11')">
                                        <InputNumber v-model="addForm.person_11" placeholder="请输入" :max="300" :min="1" :step="1"></InputNumber>
                                    </FormItem>
                                    <FormItem label="体重(Kg)" prop="person_12" :rules="[{required: itemIsRequired('person_12'), message: '请输入体重(Kg)', type: 'number', trigger: 'blur'}]" v-if="itemIsVisible('person_12')">
                                        <InputNumber v-model="addForm.person_12" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                                    </FormItem>
                                    <FormItem label="健康状况" prop="person_13" :rules="itemIsRequired('person_13') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_13')">
                                        <Select v-model="addForm.person_13" filterable clearable>
                                            <Option v-for="item in person_13Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="婚姻状况" prop="person_14" :rules="itemIsRequired('person_14') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_14')">
                                        <Select v-model="addForm.person_14" filterable clearable>
                                            <Option v-for="item in person_14Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="参加工作时间" prop="person_15" :rules="itemIsRequired('person_15') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_15')">
                                        <DatePicker type="date" placeholder="请选择" v-model="addForm.person_15" :options="disabledOptions"></DatePicker>
                                    </FormItem>
                                    <FormItem label="第一学历" prop="person_16" :rules="itemIsRequired('person_16') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_16')">
                                        <Select v-model="addForm.person_16" filterable clearable>
                                            <Option v-for="item in person_16Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="最高学历" prop="person_17" :rules="itemIsRequired('person_17') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_17')">
                                        <Select v-model="addForm.person_17" filterable clearable>
                                            <Option v-for="item in person_17Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label="家庭详细地址" prop="person_18" :rules="[{required: itemIsRequired('person_18'), message: '请输入家庭详细地址', trigger: 'blur'}]"  v-if="itemIsVisible('person_18')">
                                        <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.person_18" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="户口所在地" prop="person_19" :rules="[{required: itemIsRequired('person_19'), message: '请选择户口所在地', trigger: 'change'}]" v-if="itemIsVisible('person_19')">
                                        <treeselect v-model="addForm.person_19"
                                                    :max-height="130"
                                                    :options="countyOpts"
                                                    :disable-branch-nodes="true"
                                                    placeholder="请选择"
                                                    loadingText="加载中..."
                                                    noOptionsText="暂无数据"
                                                    noResultsText="没有匹配数据"
                                                    @select="handlePerson_19Selected">
                                            <div slot="value-label" slot-scope="{ node }">{{ node.raw.full_label }}</div>
                                        </treeselect>
                                    </FormItem>
                                    <FormItem label="邮箱" prop="person_20" :rules="itemIsRequired('person_20') ? addFormRule.emailRequiredValidator : addFormRule.emailValidator" v-if="itemIsVisible('person_20')">
                                        <Input type="text" show-word-limit :maxlength="60" v-model.trim="addForm.person_20" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="爱好和特长" prop="person_21" :rules="itemIsRequired('person_21') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('person_21')">
                                        <quill-editor v-model="addForm.person_21" :options="editorOption"></quill-editor>
                                    </FormItem>
                                    <FormItem label="通信地址" prop="person_22" :rules="[{required: itemIsRequired('person_22'), message: '请输入通信地址', trigger: 'blur'}]" v-if="itemIsVisible('person_22')">
                                        <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.person_22" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="手机号码" prop="person_23" :rules="itemIsRequired('person_23') ? addFormRule.mobileRequiredValidator : addFormRule.mobileValidator" v-if="itemIsVisible('person_23')">
                                        <Input type="text" show-word-limit :maxlength="11" v-model.trim="addForm.person_23" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="身份证号码" prop="person_24" :rules="itemIsRequired('person_24') ? addFormRule.idNumberRequiredValidator : addFormRule.idNumberValidator" v-if="itemIsVisible('person_24')">
                                        <Input type="text" show-word-limit :maxlength="18" v-model.trim="addForm.person_24" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="现居住地" prop="person_25" :rules="[{required: itemIsRequired('person_25'), message: '请输入现居住地', trigger: 'blur'}]" v-if="itemIsVisible('person_25')">
                                        <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.person_25" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="紧急联系人姓名" prop="person_26" :rules="[{required: itemIsRequired('person_26'), message: '请输入紧急联系人姓名', trigger: 'blur'}]" v-if="itemIsVisible('person_26')">
                                        <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.person_26" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="紧急联系人电话" prop="person_27" :rules="itemIsRequired('person_27') ? addFormRule.mobileRequiredValidator : addFormRule.mobileValidator" v-if="itemIsVisible('person_27')">
                                        <Input type="text" show-word-limit :maxlength="11" v-model.trim="addForm.person_27" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="目前税前年薪(万元)" prop="person_28" :rules="[{required: itemIsRequired('person_28'), message: '请输入目前税前年薪(万元)', type: 'number', trigger: 'blur'}]" v-if="itemIsVisible('person_28')">
                                        <InputNumber v-model="addForm.person_28" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                                    </FormItem>
                                    <FormItem label="期望年薪(万元)" prop="person_29" :rules="[{required: itemIsRequired('person_29'), message: '请输入期望年薪(万元)', type: 'number', trigger: 'blur'}]" v-if="itemIsVisible('person_29')">
                                        <InputNumber v-model="addForm.person_29" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                                    </FormItem>
                                    <FormItem label="到岗时间" prop="person_30" :rules="itemIsRequired('person_30') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_30')">
                                        <DatePicker type="date" placeholder="请选择" v-model="addForm.person_30" :options="disabledOptions2"></DatePicker>
                                    </FormItem>
                                    <FormItem label="是否服从调配" prop="person_31" :rules="itemIsRequired('person_31') ? addFormRule.selectRequired : null" v-if="itemIsVisible('person_31')">
                                        <RadioGroup v-model="addForm.person_31">
                                            <Radio label="是">是</Radio>
                                            <Radio label="否">否</Radio>
                                        </RadioGroup>
                                    </FormItem>
                                    <FormItem label="组织关系所在地" prop="person_32" :rules="[{required: itemIsRequired('person_32'), message: '请输入组织关系所在地', trigger: 'blur'}]" v-if="itemIsVisible('person_32')">
                                        <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.person_32" placeholder="请输入"/>
                                    </FormItem>
                                    <FormItem label="自我评价" prop="person_33" :rules="itemIsRequired('person_33') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('person_33')">
                                        <quill-editor v-model="addForm.person_33" :options="editorOption"></quill-editor>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('外语水平')">外语水平</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('外语水平')">
                            <div v-for="(obj, index) in addForm.language" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="语种" :prop="`language.${index}.language_1`" :rules="itemIsRequired('language_1') ? addFormRule.selectRequired : null" v-if="itemIsVisible('language_1')">
                                            <Select v-model="obj.language_1" filterable clearable @on-change="handleLanguageChange">
                                                <Option v-for="item in language_1Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="掌握程度" :prop="`language.${index}.language_2`" :rules="itemIsRequired('language_2') ? addFormRule.selectRequired : null" v-if="itemIsVisible('language_2')">
                                            <Select v-model="obj.language_2" filterable clearable>
                                                <Option v-for="item in language_2Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeLanguage(index)">- 移除</Button>
                            </div>
                            <div class="apply-job-section-btn">
                                <Button type="text" @click="addMoreLanguage">+ 添加更多</Button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('教育经历')">教育经历<span class="tips">注：教育经历从高中起，倒序填写</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('教育经历')">
                            <div v-for="(obj, index) in addForm.education" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="起止年月" :prop="`education.${index}.education_1`" :rules="itemIsRequired('education_1') ? addFormRule.dateRangeRequiredValidator : addFormRule.dateRangeValidator" v-if="itemIsVisible('education_1')">
                                            <Row :gutter="16">
                                                <Col span="11">
                                                    <DatePicker v-model="obj.education_1" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                                </Col>
                                                <Col class="text-center" span="2">
                                                    至
                                                </Col>
                                                <Col span="11">
                                                    <DatePicker v-model="obj.education_8" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                                </Col>
                                            </Row>
                                        </FormItem>
                                        <FormItem label="毕业学校" :prop="`education.${index}.education_2`" :rules="[{required: itemIsRequired('education_2'), message: '请输入毕业学校', trigger: 'blur'}]" v-if="itemIsVisible('education_2')">
                                            <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.education_2" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="专业" :prop="`education.${index}.education_3`" :rules="[{required: itemIsRequired('education_3'), message: '请输入专业', trigger: 'blur'}]" v-if="itemIsVisible('education_3')">
                                            <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.education_3" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="学历" :prop="`education.${index}.education_4`" :rules="itemIsRequired('education_4') ? addFormRule.selectRequired : null" v-if="itemIsVisible('education_4')">
                                            <Select v-model="obj.education_4" filterable clearable>
                                                <Option v-for="item in education_4Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="学位" :prop="`education.${index}.education_5`" :rules="itemIsRequired('education_5') ? addFormRule.selectRequired : null" v-if="itemIsVisible('education_5')">
                                            <Select v-model="obj.education_5" filterable clearable>
                                                <Option v-for="item in education_5Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="教育方式" :prop="`education.${index}.education_6`" :rules="itemIsRequired('education_6') ? addFormRule.selectRequired : null" v-if="itemIsVisible('education_6')">
                                            <RadioGroup v-model="obj.education_6">
                                                <Radio v-for="obj in education_6Array" :label="obj.name" :key="obj.id">{{obj.name}}</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                        <FormItem label="证明人" :prop="`education.${index}.education_7`" :rules="[{required: itemIsRequired('education_7'), message: '请输入证明人', trigger: 'blur'}]" v-if="itemIsVisible('education_7')">
                                            <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.education_7" placeholder="请输入"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeEducation(index)">- 移除</Button>
                            </div>
                            <div class="apply-job-section-btn">
                                <Button type="text" @click="addMoreEducation">+ 添加更多</Button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('工作经历')">工作经历<span class="tips">注：工作经历不含实习经历，倒序填写</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('工作经历')">
                            <div v-for="(obj, index) in addForm.work" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="起止年月" :prop="`work.${index}.work_1`" :rules="itemIsRequired('work_1') ? addFormRule.dateRangeRequiredValidator : addFormRule.dateRangeValidator" v-if="itemIsVisible('work_1')">
                                            <Row :gutter="16">
                                                <Col span="11">
                                                    <DatePicker v-model="obj.work_1" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                                </Col>
                                                <Col class="text-center" span="2">
                                                    至
                                                </Col>
                                                <Col span="11">
                                                    <DatePicker v-model="obj.work_8" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                                </Col>
                                            </Row>
                                        </FormItem>
                                        <FormItem label="工作单位" :prop="`work.${index}.work_2`" :rules="[{required: itemIsRequired('work_2'), message: '请输入工作单位', trigger: 'blur'}]" v-if="itemIsVisible('work_2')">
                                            <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.work_2" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="单位性质" :prop="`work.${index}.work_3`" :rules="itemIsRequired('work_3') ? addFormRule.selectRequired : null" v-if="itemIsVisible('work_3')">
                                            <Select v-model="obj.work_3" filterable clearable>
                                                <Option v-for="item in work_3Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="职务/职位" :prop="`work.${index}.work_4`" :rules="[{required: itemIsRequired('work_4'), message: '请输入职务/职位', trigger: 'blur'}]" v-if="itemIsVisible('work_4')">
                                            <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.work_4" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="证明人" :prop="`work.${index}.work_5`" :rules="[{required: itemIsRequired('work_5'), message: '请输入证明人', trigger: 'blur'}]" v-if="itemIsVisible('work_5')">
                                            <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.work_5" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="联系电话" :prop="`work.${index}.work_6`" :rules="itemIsRequired('work_6') ? addFormRule.contactNumberRequiredValidator : addFormRule.contactNumberValidator" v-if="itemIsVisible('work_6')">
                                            <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.work_6" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="工作内容及业绩" :prop="`work.${index}.work_7`" :rules="itemIsRequired('work_7') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('work_7')">
                                            <quill-editor v-model="obj.work_7" :options="editorOption"></quill-editor>
                                        </FormItem>
                                        <FormItem label="离职原因" :prop="`work.${index}.work_9`" :rules="itemIsRequired('work_9') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('work_9')">
                                            <quill-editor v-model="obj.work_9" :options="editorOption"></quill-editor>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeWork(index)">- 移除</Button>
                            </div>
                            <div class="apply-job-section-btn">
                                <Button type="text" @click="addMoreWork">+ 添加更多</Button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('项目介绍')">项目经验</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('项目介绍')">
                            <div v-for="(obj, index) in addForm.project" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="项目名称" :prop="`project.${index}.project_1`" :rules="[{required: itemIsRequired('project_1'), message: '请输入项目名称', trigger: 'blur'}]" v-if="itemIsVisible('project_1')">
                                            <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.project_1" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="项目描述" :prop="`project.${index}.project_2`" :rules="itemIsRequired('project_2') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('project_2')">
                                            <quill-editor v-model="obj.project_2" :options="editorOption"></quill-editor>
                                        </FormItem>
                                        <FormItem label="项目职责" :prop="`project.${index}.project_3`" :rules="itemIsRequired('project_3') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('project_3')">
                                            <quill-editor v-model="obj.project_3" :options="editorOption"></quill-editor>
                                        </FormItem>
                                        <FormItem label="项目业绩" :prop="`project.${index}.project_4`" :rules="itemIsRequired('project_4') ? addFormRule.editorContentRequired : addFormRule.editorContentLength" v-if="itemIsVisible('project_4')">
                                            <quill-editor v-model="obj.project_4" :options="editorOption"></quill-editor>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeProject(index)">- 移除</Button>
                            </div>
                            <div class="apply-job-section-btn">
                                <Button type="text" @click="addMoreProject">+ 添加更多</Button>
                            </div>

                            <div class="apply-job-section-block divider-line no-hover" v-if="itemIsVisible('project_5')">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="重大奖惩情况" prop="project_5" :rules="itemIsRequired('project_5') ? addFormRule.editorContentRequired : addFormRule.editorContentLength">
                                            <quill-editor v-model="addForm.project_5" :options="editorOption"></quill-editor>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('家庭主要成员')">家庭主要成员<span class="tips">注：填写主要家庭成员</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('家庭主要成员')">
                            <div v-for="(obj, index) in addForm.family" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="姓名" :prop="`family.${index}.family_1`" :rules="[{required: itemIsRequired('family_1'), message: '请输入姓名', trigger: 'blur'}]" v-if="itemIsVisible('family_1')">
                                            <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.family_1" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="出生年月" :prop="`family.${index}.family_2`" :rules="itemIsRequired('family_2') ? addFormRule.selectRequired : null" v-if="itemIsVisible('family_2')">
                                            <DatePicker v-model="obj.family_2" type="date" placeholder="请选择" :options="disabledOptions"></DatePicker>
                                        </FormItem>
                                        <FormItem label="关系" :prop="`family.${index}.family_3`" :rules="itemIsRequired('family_3') ? addFormRule.selectRequired : null" v-if="itemIsVisible('family_3')">
                                            <Select v-model="obj.family_3" filterable clearable>
                                                <Option v-for="item in family_3Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="政治面貌" :prop="`family.${index}.family_4`" :rules="itemIsRequired('family_4') ? addFormRule.selectRequired : null" v-if="itemIsVisible('family_4')">
                                            <Select v-model="obj.family_4" filterable clearable>
                                                <Option v-for="item in family_4Array" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="工作单位及职务" :prop="`family.${index}.family_5`" :rules="[{required: itemIsRequired('family_5'), message: '请输入工作单位及职务', trigger: 'blur'}]" v-if="itemIsVisible('family_5')">
                                            <Input type="text" show-word-limit :maxlength="100" v-model.trim="obj.family_5" placeholder="请输入"/>
                                        </FormItem>
                                        <FormItem label="联系电话" :prop="`family.${index}.family_6`" :rules="itemIsRequired('family_6') ? addFormRule.contactNumberRequiredValidator : addFormRule.contactNumberValidator" v-if="itemIsVisible('family_6')">
                                            <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.family_6" placeholder="请输入"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeFamily(index)">- 移除</Button>
                            </div>
                            <div class="apply-job-section-btn">
                                <Button type="text" @click="addMoreFamily">+ 添加更多</Button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('其他问题')">其他问题</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('其他问题')">
                            <Row :gutter="16">
                                <Col span="24">
                                    <FormItem label="问题描述">
                                        <div class="multiline-content" v-html="resumeFields.find(item => item.id === 'others_1')?.otherDesc"></div>
                                    </FormItem>
                                    <FormItem label="问题回复" prop="others_1" :rules="itemIsRequired('others_1') ? addFormRule.editorContentRequired : addFormRule.editorContentLength">
                                        <quill-editor v-model="addForm.others_1" :options="editorOption"></quill-editor>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>

                        <div class="divider" v-if="itemIsVisible('condition_1')">当前情况<span class="tips">注：填写当前劳动合同情况</span></div>
                        <div class="apply-job-section" v-if="itemIsVisible('condition_1')">
                            <div class="apply-job-section-default">
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="合同情况" prop="contact_status" :rules="[{required: true, message: '请选择合同情况', trigger: 'change'}]">
                                            <Select v-model="addForm.contact_status" clearable @on-change="handleContactStatusChange">
                                                <Option value="1">目前与其他单位不存在劳动关系</Option>
                                                <Option value="2">目前与原单位尚未解除劳动关系，但承诺在到岗时，可以提供解除劳动关系证明材料</Option>
                                                <Option value="3">其他</Option>
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row :gutter="16" v-if="showContactMemo">
                                    <Col span="24">
                                        <FormItem label="其他情况" prop="contact_memo" :rules="[{required: true, message: '请输入其他情况', trigger: 'blur'}]">
                                            <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.contact_memo" placeholder="请输入"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="允许情况核实" prop="contact_inspect" :rules="[{required: true, message: '请选择是否允许情况核实', trigger: 'change'}]">
                                            <div class="multiline-content">
                                                本人郑重声明，我在表中提供的有关信息真实准确，愿意承担因提供任何虚假与不实信息所造成的一切后果。<br />本人
                                                <RadioGroup class="radio-group" v-model="addForm.contact_inspect">
                                                    <Radio label="同意">同意</Radio>
                                                    <Radio label="不同意">不同意</Radio>
                                                </RadioGroup>
                                                贵单位在必要的情况下对有关信息进行核实。
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="申请人签名" prop="contact_signature" :rules="[{required: true, message: '请输入申请人签名', trigger: 'blur'}]">
                                            <div class="signature-area">
                                                <vueEsign ref="esign"
                                                          :width="esign.width"
                                                          :height="esign.height"
                                                          :isCrop="esign.isCrop"
                                                          :lineWidth="esign.lineWidth"
                                                          :lineColor="esign.lineColor"
                                                          :bgColor.sync="esign.bgColor"></vueEsign>
                                                <div class="signature-button"><Button type="primary" size="small" @click="handleReset">清空</Button><span class="tips">注意：请按住鼠标左键在上面空白处移动鼠标签写姓名</span></div>
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row :gutter="16">
                                    <Col span="24">
                                        <FormItem label="个人自荐" prop="contact_recommend">
                                            <quill-editor v-model="addForm.contact_recommend" :options="editorOption"></quill-editor>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div class="submit-btn">
                            <Button size="large" type="primary" shape="circle" :loading="loading" @click="handleSubmit">确认提交</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <Modal v-model="showSuccess" :mask-closable="false" :closable="false" :width="320" footer-hide>
            <div class="ivu-modal-confirm">
                <div class="ivu-modal-confirm-head">
                    <div class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-success"><i class="ivu-icon ivu-icon-ios-checkmark-circle"></i></div>
                </div>
                <div class="ivu-modal-confirm-body">
                    <div class="ivu-modal-confirm-content">
                        <div class="title">报名成功！</div>
                        <div class="content">请留意右上角 站内消息 了解应聘进度</div>
                    </div>
                </div>
                <div class="ivu-modal-confirm-footer">
                    <Button type="primary" @click="goPositionDetail">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
// 调用富文本编辑器
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";
import {getPositionByCode, reqApplyPosition, reqUploadSignature, reqGetAvailsPerApplicant} from "../../api/client-api";
import {reqDeleteFile, reqDictionaryMapByCodeList, uploadFileURL} from "../../api/user-api";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import county from "../../assets/area/county";
import vueEsign from 'vue-esign'
import {
    checkContactNumber,
    checkEditorContent,
    checkEmail,
    check18IdNumber,
    checkMobile,
    convertBase64UrlToBlob
} from "../../assets/js/utils";

export default {
    components: {Header, Footer, Treeselect, quillEditor, vueEsign},
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            uploadFiles: uploadFileURL,
            companyName: '',
            projectName: '',
            projectCode: '',
            positionCode: '',
            headerBanner: '',
            header: null,
            imgUrl: null,
            showImg: false,
            showPreview: false,
            showContactMemo: false,
            showSuccess: false,
            loading: false,
            countyOpts: county,
            position_1Array: [],
            person_7Array: [],
            person_8Array: [],
            person_9Array: [],
            person_13Array: [],
            person_14Array: [],
            person_16Array: [],
            person_17Array: [],
            language_1Array: [],
            language_2Array: [],
            education_4Array: [],
            education_5Array: [],
            education_6Array: [],
            work_3Array: [],
            family_3Array: [],
            family_4Array: [],
            esign: {
                width: 786,
                height: 300,
                isCrop: true,
                lineWidth: 4,
                lineColor: '#000000',
                bgColor: ''
            },
            addForm: {
                keywords: [],
                positionId: null,
                positionName: null,
                deptName: null,
                position_1: null,
                person_1: null,
                person_2: null,
                person_4: '男',
                person_6: null,
                person_8: null,
                person_9: null,
                person_10: null,
                person_11: null,
                person_12: null,
                person_13: null,
                person_14: null,
                person_15: null,
                person_16: null,
                person_17: null,
                person_19: null,
                person_28: null,
                person_29: null,
                person_31: '是',
                language: [
                    {language_1: null, language_2: null}
                ],
                education: [
                    {education_1: null, education_2: null, education_3: null, education_4: null, education_5: null, education_6: '统招', education_7: null, education_8: null}
                ],
                work: [
                    {work_1: null, work_2: null, work_3: null, work_4: null, work_5: null, work_6: null, work_7: null, work_8: null, work_9: null}
                ],
                project: [
                    {project_1: null, project_2: null, project_3: null, project_4: null}
                ],
                project_5: null,
                family: [
                    {family_1: null, family_2: null, family_3: null, family_4: null, family_5: null, family_6: null}
                ],
                others_1: null,
                contact_status: null,
                contact_memo: null,
                contact_inspect: '同意',
                contact_signature: null,
                contact_recommend: null,
            },
            addFormRule: {
                selectRequired: [{required: true, trigger: 'change',
                    validator: (rule, value, callback) => {
                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        let label = this.resumeFields.find(item => item.id === itemId).name;
                        if (!value) {
                            return callback('请选择' + (label ? label : ''));
                        }

                        return callback();
                    }
                }],
                dateRangeRequiredValidator: [{required: true, trigger: 'change',
                    validator: (rule, value, callback) => {
                        let path = rule.field.split('.');
                        let objList = this.addForm[path[0]];
                        let obj = objList[path[1] * 1];
                        let newId = path[2].substring(0, path[2].length - 1);

                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        let label = this.resumeFields.find(item => item.id === itemId).name;
                        if (!value || !obj[newId + '8']) {
                            return callback('请选择' + (label ? label : ''));
                        }
                        if (value >= obj[newId + '8']) {
                            return callback('起始年月不能大于截止年月');
                        }

                        return callback();
                    }
                }],
                dateRangeValidator: [{trigger: 'change',
                    validator: (rule, value, callback) => {
                        let path = rule.field.split('.');
                        let objList = this.addForm[path[0]];
                        let obj = objList[path[1] * 1];
                        let newId = path[2].substring(0, path[2].length - 1);
                        if (value && obj[newId + '8'] && value >= obj[newId + '8']) {
                            return callback('起始年月不能大于截止年月');
                        }

                        return callback();
                    }
                }],
                editorContentRequired: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        let item = this.resumeFields.find(item => item.id === itemId);
                        let maxLen = item.maxLength ? item.maxLength : 500;
                        if (item) {
                            if (item.isRequired && !value) {
                                return callback(new Error('请输入' + (itemId === 'others_1' ? '问题回复' : item.name)));
                            }
                            if (value && !checkEditorContent(value, maxLen)) {
                                return callback(new Error((itemId === 'others_1' ? '问题回复' : item.name) + '不能超过' + maxLen + '字符'));
                            }
                        }

                        return callback();
                    }
                }],
                editorContentLength: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        let item = this.resumeFields.find(item => item.id === itemId);
                        if (item && value && !checkEditorContent(value, item.maxLength)) {
                            return callback(new Error((itemId === 'others_1' ? '问题回复' : item.name) + '不能超过' + item.maxLength + '字符'));
                        }

                        return callback();
                    }
                }],
                contact_recommend: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkEditorContent(value, 500)) {
                            return callback(new Error('个人自荐不能超过500字符'));
                        }

                        return callback();
                    }
                }],
                emailRequiredValidator: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback('请输入邮箱');
                        }
                        if (!checkEmail(value)) {
                            return callback('请输入正确的邮箱');
                        }

                        return callback();
                    }
                }],
                emailValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkEmail(value)) {
                            return callback('请输入正确的邮箱');
                        }

                        return callback();
                    }
                }],
                mobileRequiredValidator: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        let name = this.resumeFields.find(item => item.id === rule.field)?.name;
                        if (!value) {
                            return callback('请输入' + (name ? name : '手机号码'));
                        }
                        if (!checkMobile(value)) {
                            return callback('请输入正确的' + (name ? name : '手机号码'));
                        }

                        return callback();
                    }
                }],
                mobileValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkMobile(value)) {
                            return callback('请输入正确的手机号码');
                        }

                        return callback();
                    }
                }],
                idNumberRequiredValidator: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback('请输入身份证号码');
                        }
                        if (!check18IdNumber(value)) {
                            return callback('请输入正确的身份证号码');
                        }

                        return callback();
                    }
                }],
                idNumberValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !check18IdNumber(value)) {
                            return callback('请输入正确的身份证号码');
                        }

                        return callback();
                    }
                }],
                contactNumberRequiredValidator: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback('请输入联系电话');
                        }
                        if (!checkContactNumber(value)) {
                            return callback('请输入正确的联系电话');
                        }

                        return callback();
                    }
                }],
                contactNumberValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkContactNumber(value)) {
                            return callback('请输入正确的联系电话');
                        }

                        return callback();
                    }
                }],
            },
            disabledOptions: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now() - 86400000;
                }
            },
            disabledOptions2: {
                disabledDate(date) {
                    return date && date.valueOf() <= Date.now() - 86400000;
                }
            },
            disabledMonthOptions: {
                disabledDate (date) {
                    return date && date.valueOf() > new Date();
                }
            }
        }
    },
    methods: {
        validateAndRedirect() {
            reqGetAvailsPerApplicant({projectCode: this.projectCode, positionCode: this.positionCode}).then((res)=>{
                if (res.data.data) {
                    if (res.data.data.availsPerPosition <= 0) {
                        this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                    } else if (res.data.data.availsPerProject <= 0) {
                        this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                    } else {
                        this.getPosition();
                        this.getDictionaryMapByCodeList();
                    }
                } else {
                    this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                }
            }).catch((err)=>{
                console.log('err===', err);
                this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
            })
        },
        getPosition() {
            getPositionByCode({code: this.positionCode}).then((res)=>{
                if (res.data.data) {
                    this.companyName = res.data.data.companyName ? res.data.data.companyName : '';
                    this.projectName = res.data.data.projectName ? res.data.data.projectName : '';
                    this.headerBanner = res.data.data.banner;
                    this.resumeFields = JSON.parse(res.data.data.resumeFields);
                    this.addForm.positionId = res.data.data.id;
                    this.addForm.positionName = res.data.data.name;
                    this.addForm.deptName = res.data.data.deptName;
                }
            }).catch((err)=>{
                this.headerBanner = '';
                this.resumeFields = null;
                console.log('err===', err);
            })
        },
        goProjectHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}, query: {tab: 'position'}});
        },
        goJobDetail() {
            this.$router.push({name: 'position', params: {id: this.positionCode}});
        },
        getDictionaryMapByCodeList() {
            let codes = ['information_source', 'nation', 'job_title', 'politics_status', 'health_condition',
                'marital_status', 'highest_degree', 'language', 'level', 'degree', 'nature_of_business',
                'educational_mode', 'relationship'];
            reqDictionaryMapByCodeList(codes).then((res) => {
                this.position_1Array = res.data.data.information_source;
                this.person_7Array = res.data.data.nation;
                this.person_8Array = res.data.data.job_title;
                this.person_9Array = res.data.data.politics_status;
                this.family_4Array = res.data.data.politics_status;
                this.person_13Array = res.data.data.health_condition;
                this.person_14Array = res.data.data.marital_status;
                this.person_16Array = res.data.data.highest_degree;
                this.person_17Array = res.data.data.highest_degree;
                this.education_4Array = res.data.data.highest_degree;
                this.language_1Array = res.data.data.language;
                this.language_2Array = res.data.data.level;
                this.education_5Array = res.data.data.degree;
                this.work_3Array = res.data.data.nature_of_business;
                this.education_6Array = res.data.data.educational_mode;
                this.family_3Array = res.data.data.relationship;
            }).catch(() => {
                this.position_1Array = [];
                this.person_7Array = [];
                this.person_8Array = [];
                this.person_9Array = [];
                this.family_4Array = [];
                this.person_13Array = [];
                this.person_14Array = [];
                this.person_16Array = [];
                this.person_17Array = [];
                this.education_4Array = [];
                this.language_1Array = [];
                this.language_2Array = [];
                this.education_5Array = [];
                this.work_3Array = [];
                this.education_6Array = [];
                this.family_3Array = [];
            })
        },
        handleBeforeUpload(file) {
            if (file.size > 5 * 1024 * 1024) {
                this.$Message.error('文件内容不得超过5M');
                return false;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imgUrl = reader.result;
            }
        },
        //上传文件大小
        handExceeded() {
            this.$Message.error('文件内容不得超过5M');
        },
        //上传成功
        handleSuccess(res, file) {
            if (res.code === 1) {
                this.addForm.person_1 = res.data.currentName;
                this.showImg = true;
                this.$Message.success('上传成功');
            } else {
                this.imgUrl = '';
                this.showImg = false;
                this.$Message.error('上传失败');
            }
        },
        handleImgPreview() {
            this.showPreview = true;
        },
        handleRemove() {
            reqDeleteFile([this.addForm.person_1]).then((res) => {
                if (res.data.data && res.data.data.length > 0) {
                    this.imgUrl = '';
                    this.addForm.person_1 = '';
                    this.showImg = false;
                }
            })
        },
        handlePerson_6Selected(val) {
            this.addForm.person_6FullLabel = val.full_label;
        },
        handlePerson_19Selected(val) {
            this.addForm.person_19FullLabel = val.full_label;
        },
        handlePerson9Change(val) {
            if (val !== 202703) this.addForm.person_10 = null;
        },
        handleLanguageChange(val) {
            this.addForm.language_2 = null;
        },
        removeLanguage(idx) {
            this.addForm.language.splice(idx, 1);
        },
        addMoreLanguage() {
            this.addForm.language.push({language_1: null, language_2: null});
        },
        removeEducation(idx) {
            this.addForm.education.splice(idx, 1);
        },
        addMoreEducation() {
            this.addForm.education.push({
                education_1: null,
                education_2: null,
                education_3: null,
                education_4: null,
                education_5: null,
                education_6: '统招',
                education_7: null,
                education_8: null
            });
        },
        removeWork(idx) {
            this.addForm.work.splice(idx, 1);
        },
        addMoreWork() {
            this.addForm.work.push({
                work_1: null,
                work_2: null,
                work_3: null,
                work_4: null,
                work_5: null,
                work_6: null,
                work_7: null,
                work_8: null,
                work_9: null
            });
        },
        removeProject(idx) {
            this.addForm.project.splice(idx, 1);
        },
        addMoreProject() {
            this.addForm.project.push({project_1: null, project_2: null, project_3: null, project_4: null});
        },
        removeFamily(idx) {
            this.addForm.family.splice(idx, 1);
        },
        addMoreFamily() {
            this.addForm.family.push({
                family_1: null,
                family_2: null,
                family_3: null,
                family_4: null,
                family_5: null,
                family_6: null
            });
        },
        handleContactStatusChange(val) {
            if (val === '3') {
                this.showContactMemo = true;
            } else {
                this.showContactMemo = false;
                this.addForm.contact_memo = null;
            }
        },
        handleReset() {
            this.$refs.esign.reset();
        },
        categoryIsVisible(categoryName) {
            let idx = this.resumeFields?.filter(item => item.category === categoryName).findIndex(item => item.isVisible === true);
            return (idx >= 0);
        },
        itemIsVisible(itemId) {
            let obj = this.resumeFields?.find(item => item.id === itemId);
            return (obj && obj.isVisible);
        },
        itemIsRequired(itemId) {
            let obj = this.resumeFields?.find(item => item.id === itemId);
            return (obj && obj.isRequired);
        },
        handleSubmit() {
            let that = this;
            that.loading = true;
            // 是否需要上传签名
            let isConditionVisible = this.resumeFields?.find(item => item.id === 'condition_1')?.isVisible;
            if (isConditionVisible) {
                that.$refs.esign.generate().then(res => {
                    that.addForm.contact_signature = null;
                    const formData = new FormData();
                    formData.append("file", convertBase64UrlToBlob(res), Date.now() + '.jpeg'); // 转换成Blob上传
                    // formData.append("file", this.base64toFile(res, Date.now() + '.jpeg')); // 转换成file上传
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            that.addForm.contact_signature = res.data.data.currentName;
                            that.$refs['addForm'].validate((valid) => {
                                if (!valid) {
                                    that.loading = false;
                                    return;
                                }

                                let now = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                                let myResume = that.createResume(now);
                                let params = {
                                    positionId: this.addForm.positionId,
                                    keywords: this.addForm.keywords.join('-'),
                                    resume: JSON.stringify(myResume),
                                    submitTime: now
                                }
                                reqApplyPosition(params).then((res) => {
                                    if (res.data.code === 1) {
                                        that.showSuccess = true;
                                    } else {
                                        that.loading = false;
                                        that.$Message.error('添加失败');
                                    }
                                }).catch(() => {
                                    that.loading = false;
                                    that.$Message.error('添加失败');
                                })
                            })
                        } else {
                            that.$refs['addForm'].validateField('contact_signature');
                            that.loading = false;
                        }
                    }).catch((err) => {
                        that.$refs['addForm'].validateField('contact_signature');
                        that.loading = false;
                        console.log('uploadSignature error: ', err);
                    });
                }).catch(err => {
                    // 画布没有签字时会执行这里 'Not Signned'
                    that.addForm.contact_signature = null;
                    that.$refs['addForm'].validateField('contact_signature');
                    that.loading = false;
                })
            } else {
                that.$refs['addForm'].validate((valid) => {
                    if (!valid) {
                        that.loading = false;
                        return;
                    }

                    let now = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                    let myResume = that.createResume(now);
                    let params = {
                        positionId: this.addForm.positionId,
                        keywords: this.addForm.keywords.join('-'),
                        resume: JSON.stringify(myResume),
                        submitTime: now
                    }
                    reqApplyPosition(params).then((res) => {
                        if (res.data.code === 1) {
                            that.showSuccess = true;
                        } else {
                            that.loading = false;
                            that.$Message.error('添加失败');
                        }
                    }).catch(() => {
                        that.loading = false;
                        that.$Message.error('添加失败');
                    })
                })
            }
        },
        createResume(now) {
            this.addForm.keywords = [this.addForm.positionName];
            let resume = [{
                "name": "职位信息",
                "children": [
                    {"name": "投递时间", "value": now},
                    {"name": "应聘职位", "value": this.addForm.positionName},
                    {"name": "职位URL", "value": window.location.protocol + "//" + window.location.host + "/position/" + this.positionCode},
                    {"name": "应聘部门", "value": this.addForm.deptName},
                    {"name": "应聘项目", "value": this.projectName},
                    {"name": "应聘公司", "value": this.companyName}
                ]
            }];
            this.resumeFields?.forEach(field => {
                if (field.isVisible) {
                    let category = resume.find(item => item.name === field.category);
                    if (!category) {
                        if (field.category === '当前情况') {
                            category = {"name": field.category, "value": ''};
                        } else {
                            category = {"name": field.category, "children": []};
                        }
                        resume.push(category);
                    }

                    let obj = this[field.id + 'Array']?.find(item => item.id === this.addForm[field.id]);
                    if (field.category === '职位信息') {
                        let val = obj ? obj.name : this.addForm[field.id];
                        category.children = [{"name": field.name, "value": val}, ...category.children];
                    } else if (field.category === '其他问题') {
                        let name = this.resumeFields.find(item => item.id === 'others_1')?.otherDesc;
                        category.children = [{"name": name, "value": this.addForm[field.id]}, ...category.children];
                    } else if (field.category === '个人资料') {
                        let val = obj ? obj.name : this.addForm[field.id];
                        if (field.id === 'person_5' || field.id === 'person_10' || field.id === 'person_15' || field.id === 'person_30') {
                            val = this.addForm[field.id] ? this.$moment(this.addForm[field.id]).format('YYYY-MM-DD') : null;
                        }
                        if (field.id === 'person_6' || field.id === 'person_19') {
                            val = this.addForm[field.id+'FullLabel'];
                        }
                        if (field.id === 'person_8') {
                            let labelArray = [];
                            this.addForm[field.id]?.forEach(id => {
                                labelArray.push(this[field.id+'Array'].find(f => f.id === id).name);
                            });
                            val = labelArray.join('，');
                        }

                        if (!(field.id === 'person_10' && this.addForm.person_9 !== '中共党员')) {
                            category.children.push({"name": field.name, "value": val});
                        }
                        if ((field.id === 'person_2' || field.id === 'person_23') && val) { //姓名、手机号码
                            this.addForm.keywords.push(val);
                        }
                    } else if (field.category === '当前情况') {
                        let content =
                            "<div>" +
                            "   <div>" +
                            "       <p>申请人劳动合同的情况（从下面中选一项）：</p>" +
                            "       <ul>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '1' ? "checked" : "") + ">目前与其他单位不存在劳动关系</li>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '2' ? "checked" : "") + ">目前与原单位尚未解除劳动关系，但承诺在到岗时，可以提供解除劳动关系证明材料</li>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '3' ? "checked" : "") + ">其他：" + (this.addForm.contact_status === '3' ? this.addForm.contact_memo : "") + "</li>" +
                            "       </ul>" +
                            "   </div>" +
                            "   <p>本人郑重声明，我在表中提供的有关信息真实准确，愿意承担因提供任何虚假与不实信息所造成的一切后果。</p>" +
                            "   <p>本人<input type='checkbox' disabled " + (this.addForm.contact_inspect === '同意' ? "checked" : "") + ">同意<input type='checkbox' disabled " + (this.addForm.contact_inspect === '不同意' ? "checked" : "") + ">不同意  贵单位在必要的情况下对有关信息进行核实。</p>" +
                            "   <div class='sign'>" +
                            "       <div class='person'>申请人：<img src='${downloadFileURL}" + this.addForm.contact_signature + "/'></img></div>" +
                            "       <div class='date'>申请时间：" + now + "</div>" +
                            "       <div class='recommend'>" +
                            "           <label>个人自荐:</label>" +
                            "           <div class='multiline-content'>" + (this.addForm.contact_recommend ? this.addForm.contact_recommend : "") + "</div>" +
                            "       </div>" +
                            "   </div>" +
                            "</div>";
                        category.value = content;
                    } else if (field.id === 'project_5') {
                        category[field.id] = {"name": field.name, "value": this.addForm[field.id]};
                    } else if (field.category === '外语水平' || field.category === '教育经历'
                        || field.category === '工作经历' || field.category === '项目介绍' || field.category === '家庭主要成员') {
                        let _index = (field.id.indexOf('_') >= 0 ? field.id.indexOf('_') : field.id.length);
                        let _id = field.id.substring(0, _index);
                        let _objArray = this.addForm[_id];
                        for (let i in _objArray) {
                            let _obj = _objArray[i];
                            let _opts = this[field.id + 'Array']?.find(item => item.id === _obj[field.id]);
                            let _objVal = _opts ? _opts.name : _obj[field.id];
                            if (field.id === 'education_1') {
                                _objVal = _obj['education_1'] ? this.$moment(_obj['education_1']).format('YYYY-MM') + ' 至 ' : 'YYYY-MM 至 ';
                                _objVal += _obj['education_8'] ? this.$moment(_obj['education_8']).format('YYYY-MM') : 'YYYY-MM';
                            }
                            if (field.id === 'work_1') {
                                _objVal = _obj['work_1'] ? this.$moment(_obj['work_1']).format('YYYY-MM') + ' 至 ' : 'YYYY-MM 至 ';
                                _objVal += _obj['work_8'] ? this.$moment(_obj['work_8']).format('YYYY-MM') : 'YYYY-MM';
                            }
                            if (field.id === 'family_2') {
                                _objVal = _obj[field.id] ? this.$moment(_obj[field.id]).format('YYYY-MM-DD') : null;
                            }
                            let child = category.children[i] ? category.children[i] : {};
                            category.children[i] = Object.assign({}, child, {[field.id]: field.name, [field.id+'value']: _objVal});
                        }
                    }
                }
            });

            return resume;
        },
        goPositionDetail() {
            this.showSuccess = false;
            this.loading = false;
            this.$router.push('/position/' + this.positionCode);
        }
    },
    mounted() {
        this.$refs['addForm'].resetFields();
        let token = sessionStorage.getItem('Authorization');
        this.header = {Authorization: token};
        this.projectCode = this.$route.query.projectCode;
        this.positionCode = this.$route.query.positionCode;
        this.validateAndRedirect();
    }
};
</script>

<style scoped>

</style>
