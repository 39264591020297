<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="报名申请" left-text="返回" left-arrow fixed @click-left="handleGoBack"/>
        <div class="content">
            <div class="main-content">
                <div class="apply-job">
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar ref="pickerRef" :valueKey="pickerKey" :title="pickerTitle" :columns="pickerColumns" @confirm="onPickerConfirm" @cancel="showPicker = false"/>
                    </van-popup>
                    <van-popup v-model="showDatePicker" position="bottom">
                        <van-datetime-picker v-model="currentDate" type="date" :title="datePickerTitle" :min-date="minDate" :max-date="maxDate" @confirm="onDatePickerConfirm" @cancel="showDatePicker = false"/>
                    </van-popup>
                    <van-popup v-model="showCountyPicker" position="bottom">
                        <van-cascader v-model="countyPickerValue" :title="countyPickerTitle" :options="countyOpts" :field-names="countyFieldNames" active-color="#00bbbd" @finish="onCountyPickerFinish" @close="showCountyPicker = false"/>
                    </van-popup>
                    <van-popup v-model="showMonthPicker" position="bottom">
                        <van-datetime-picker v-model="currentDate" type="year-month" :title="datePickerTitle" :min-date="minDate" :max-date="maxDate" @confirm="onMonthPickerConfirm" @cancel="showMonthPicker = false"/>
                    </van-popup>
                    <van-form ref="addForm" @submit="handleSubmit" v-model="addForm" label-colon>
                        <div class="divider">职位信息</div>
                        <div class="apply-job-section plain">
                            <van-cell-group>
                                <van-cell title="职位" :value="addForm.positionName" />
                                <van-cell title="部门" :value="addForm.deptName" />
                                <van-cell title="项目" :value="projectName" />
                                <van-cell title="单位" :value="companyName" />
                                <van-field readonly clickable :value="addForm.position_1" label="信息来源" placeholder="请选择" :required="itemIsRequired('position_1')" @click="handleShowPicker('position_1', '信息来源')" :rules="[{required: itemIsRequired('position_1'), message: '请选择信息来源' }]" v-if="itemIsVisible('position_1')"/>
                            </van-cell-group>
                        </div>
                        <div class="divider" v-if="categoryIsVisible('个人资料')">个人资料</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('个人资料')">
                            <van-cell-group>
                                <van-field :value="addForm.person_1" label="照片" :required="itemIsRequired('person_1')" :rules="[{required: itemIsRequired('person_1'), message: '请上传照片' }]" v-if="itemIsVisible('person_1')">
                                    <template #input>
                                        <van-uploader name="person_1" v-model="fileList" accept="image/*" :max-count="1" :max-size="5 * 1024 * 1024" @oversize="handExceeded" @delete="handleRemove" :after-read="handleSuccess"/>
                                    </template>
                                </van-field>
                                <van-field v-model.trim="addForm.person_2" label="姓名" maxlength="10" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_2'), message: '请输入姓名' }]" :required="itemIsRequired('person_2')" v-if="itemIsVisible('person_2')"/>
                                <van-field v-model.trim="addForm.person_3" label="曾用名" maxlength="10" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_3'), message: '请输入曾用名' }]" :required="itemIsRequired('person_3')" v-if="itemIsVisible('person_3')"/>
                                <van-field name="person_4" label="性别" :rules="[{ required: itemIsRequired('person_4'), message: '请选择性别' }]" :required="itemIsRequired('person_4')" v-if="itemIsVisible('person_4')">
                                    <template #input>
                                        <van-radio-group v-model="addForm.person_4" direction="horizontal">
                                            <van-radio name="男">男</van-radio>
                                            <van-radio name="女">女</van-radio>
                                        </van-radio-group>
                                    </template>
                                </van-field>
                                <van-field readonly clickable :value="addForm.person_5" label="出生年月" placeholder="请选择" :required="itemIsRequired('person_5')" @click="handleShowDatePicker('person_5', '出生年月')" :rules="[{required: itemIsRequired('person_5'), message: '请选择出生年月' }]" v-if="itemIsVisible('person_5')"/>
                                <van-field readonly clickable :value="addForm.person_6" label="籍贯" placeholder="请选择" :required="itemIsRequired('person_6')" @click="handleShowCountyPicker('person_6', '籍贯')" :rules="[{required: itemIsRequired('person_6'), message: '请选择籍贯' }]" v-if="itemIsVisible('person_6')"/>
                                <van-field readonly clickable :value="addForm.person_7" label="民族" placeholder="请选择" :required="itemIsRequired('person_7')" @click="handleShowPicker('person_7', '民族')" :rules="[{required: itemIsRequired('person_7'), message: '请选择民族' }]" v-if="itemIsVisible('person_7')"/>
                                <MultiplePicker v-model="addForm.person_8" label="职称及职业资格" :columns="person_8Array" placeholder="请选择" :option="{label:'name', value:'id'}" :required="itemIsRequired('person_8')" :rules="[{required: itemIsRequired('person_8'), message: '请选择职称及职业资格' }]" v-if="itemIsVisible('person_8')"/>
                                <van-field readonly clickable :value="addForm.person_9" label="政治面貌" placeholder="请选择" :required="itemIsRequired('person_9')" @click="handleShowPicker('person_9', '政治面貌')" :rules="[{required: itemIsRequired('person_9'), message: '请选择政治面貌' }]" v-if="itemIsVisible('person_9')"/>
                                <van-field readonly clickable :value="addForm.person_10" label="入党时间" placeholder="请选择" :required="itemIsRequired('person_10')" @click="handleShowDatePicker('person_10', '入党时间')" :rules="[{required: itemIsRequired('person_10'), message: '请选择入党时间' }]" v-if="(addForm.person_9 === '中共党员' && itemIsVisible('person_10'))"/>
                                <van-field type="number" v-model="addForm.person_11" label="身高(cm)" placeholder="请输入" :rules="[{ required: itemIsRequired('person_11'), message: '请输入身高(cm)' }]" :required="itemIsRequired('person_11')" v-if="itemIsVisible('person_11')" />
                                <van-field type="number" v-model="addForm.person_12" label="体重(Kg)" placeholder="请输入" :rules="[{ required: itemIsRequired('person_12'), message: '请输入体重(Kg)' }]" :required="itemIsRequired('person_12')" v-if="itemIsVisible('person_12')" />
                                <van-field readonly clickable :value="addForm.person_13" label="健康状况" placeholder="请选择" :required="itemIsRequired('person_13')" @click="handleShowPicker('person_13', '健康状况')" :rules="[{required: itemIsRequired('person_13'), message: '请选择健康状况' }]" v-if="itemIsVisible('person_13')"/>
                                <van-field readonly clickable :value="addForm.person_14" label="婚姻状况" placeholder="请选择" :required="itemIsRequired('person_14')" @click="handleShowPicker('person_14', '婚姻状况')" :rules="[{required: itemIsRequired('person_14'), message: '请选择婚姻状况' }]" v-if="itemIsVisible('person_14')"/>
                                <van-field readonly clickable :value="addForm.person_15" label="参加工作时间" placeholder="请选择" :required="itemIsRequired('person_15')" @click="handleShowDatePicker('person_15', '参加工作时间')" :rules="[{required: itemIsRequired('person_15'), message: '请选择参加工作时间' }]" v-if="itemIsVisible('person_15')"/>
                                <van-field readonly clickable :value="addForm.person_16" label="第一学历" placeholder="请选择" :required="itemIsRequired('person_16')" @click="handleShowPicker('person_16', '第一学历')" :rules="[{required: itemIsRequired('person_16'), message: '请选择第一学历' }]" v-if="itemIsVisible('person_16')"/>
                                <van-field readonly clickable :value="addForm.person_17" label="最高学历" placeholder="请选择" :required="itemIsRequired('person_17')" @click="handleShowPicker('person_17', '最高学历')" :rules="[{required: itemIsRequired('person_17'), message: '请选择最高学历' }]" v-if="itemIsVisible('person_17')"/>
                                <van-field v-model.trim="addForm.person_18" label="家庭详细地址" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_18'), message: '请输入家庭详细地址' }]" :required="itemIsRequired('person_18')" v-if="itemIsVisible('person_18')"/>
                                <van-field readonly clickable :value="addForm.person_19" label="户口所在地" placeholder="请选择" :required="itemIsRequired('person_19')" @click="handleShowCountyPicker('person_19', '户口所在地')" :rules="[{required: itemIsRequired('person_19'), message: '请选择户口所在地' }]" v-if="itemIsVisible('person_19')"/>
                                <van-field v-model.trim="addForm.person_20" label="邮箱" maxlength="60" placeholder="请输入" show-word-limit :rules="[{ id: 'person_20', validator: emailValidator, message: '请输入邮箱', trigger: 'onBlur' }]" :required="itemIsRequired('person_20')" v-if="itemIsVisible('person_20')"/>
                                <van-field name="person_21" label="爱好和特长" :rules="[{ id: 'person_21', validator: editorContentValidator, message: '请输入爱好和特长' }]" :required="itemIsRequired('person_21')" v-if="itemIsVisible('person_21')">
                                    <template #input>
                                        <quill-editor v-model="addForm.person_21" :options="editorOption"></quill-editor>
                                    </template>
                                </van-field>
                                <van-field v-model.trim="addForm.person_22" label="通信地址" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_22'), message: '请输入通信地址' }]" :required="itemIsRequired('person_22')" v-if="itemIsVisible('person_22')"/>
                                <van-field type="tel" v-model="addForm.person_23" label="手机号码" maxlength="11" placeholder="请输入" :rules="[{ id: 'person_23', validator: mobileValidator, message: '请输入手机号码', trigger: 'onBlur' }]" :required="itemIsRequired('person_23')" v-if="itemIsVisible('person_23')" />
                                <van-field v-model.trim="addForm.person_24" label="身份证号码" maxlength="18" placeholder="请输入" show-word-limit :rules="[{ id: 'person_24', validator: idNumberValidator, message: '请输入身份证号码', trigger: 'onBlur' }]" :required="itemIsRequired('person_24')" v-if="itemIsVisible('person_24')"/>
                                <van-field v-model.trim="addForm.person_25" label="现居住地" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_25'), message: '请输入现居住地' }]" :required="itemIsRequired('person_25')" v-if="itemIsVisible('person_25')"/>
                                <van-field v-model.trim="addForm.person_26" label="紧急联系人姓名" maxlength="10" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_26'), message: '请输入紧急联系人姓名' }]" :required="itemIsRequired('person_26')" v-if="itemIsVisible('person_26')"/>
                                <van-field type="tel" v-model="addForm.person_27" label="紧急联系人电话" maxlength="11" placeholder="请输入" :rules="[{ id: 'person_27', validator: mobileValidator, message: '请输入紧急联系人电话', trigger: 'onBlur' }]" :required="itemIsRequired('person_27')" v-if="itemIsVisible('person_27')" />
                                <van-field type="number" v-model="addForm.person_28" label="目前税前年薪(万元)" placeholder="请输入" :rules="[{ required: itemIsRequired('person_28'), message: '请输入目前税前年薪(万元)' }]" :required="itemIsRequired('person_28')" v-if="itemIsVisible('person_28')" />
                                <van-field type="number" v-model="addForm.person_29" label="期望年薪(万元)" placeholder="请输入" :rules="[{ required: itemIsRequired('person_29'), message: '请输入期望年薪(万元)' }]" :required="itemIsRequired('person_29')" v-if="itemIsVisible('person_29')" />
                                <van-field readonly clickable :value="addForm.person_30" label="到岗时间" placeholder="请选择" :required="itemIsRequired('person_30')" @click="handleShowDatePicker('person_30', '到岗时间')" :rules="[{required: itemIsRequired('person_30'), message: '请选择到岗时间' }]" v-if="itemIsVisible('person_30')"/>
                                <van-field name="person_31" label="是否服从调配" :rules="[{ required: itemIsRequired('person_31'), message: '请选择是否服从调配' }]" :required="itemIsRequired('person_31')" v-if="itemIsVisible('person_31')">
                                    <template #input>
                                        <van-radio-group v-model="addForm.person_31" direction="horizontal">
                                            <van-radio name="是">是</van-radio>
                                            <van-radio name="否">否</van-radio>
                                        </van-radio-group>
                                    </template>
                                </van-field>
                                <van-field v-model.trim="addForm.person_32" label="组织关系所在地" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('person_32'), message: '请输入组织关系所在地' }]" :required="itemIsRequired('person_32')" v-if="itemIsVisible('person_32')"/>
                                <van-field name="person_33" label="自我评价" :rules="[{ id: 'person_33', validator: editorContentValidator, message: '请输入自我评价' }]" :required="itemIsRequired('person_33')" v-if="itemIsVisible('person_33')">
                                    <template #input>
                                        <quill-editor v-model="addForm.person_33" :options="editorOption"></quill-editor>
                                    </template>
                                </van-field>

                            </van-cell-group>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('外语水平')">外语水平</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('外语水平')">
                            <div v-for="(obj, index) in addForm.language" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <van-cell-group>
                                    <van-field readonly clickable :value="obj.language_1" label="语种" placeholder="请选择" :required="itemIsRequired('language_1')" @click="handleShowPicker('language_1', '语种', index)" :rules="[{required: itemIsRequired('language_1'), message: '请选择语种' }]" v-if="itemIsVisible('language_1')"/>
                                    <van-field readonly clickable :value="obj.language_2" label="掌握程度" placeholder="请选择" :required="itemIsRequired('language_2')" @click="handleShowPicker('language_2', '掌握程度', index)" :rules="[{required: itemIsRequired('language_2'), message: '掌握程度' }]" v-if="itemIsVisible('language_2')"/>
                                </van-cell-group>
                                <div class="remove-btn"><van-button plain native-type="button" type="danger" size="small" v-if="index > 0" @click="removeLanguage(index)">- 移除新增项</van-button></div>
                            </div>
                            <div class="apply-job-section-btn">
                                <van-button plain native-type="button" type="primary" size="small" @click="addMoreLanguage">+ 添加更多</van-button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('教育经历')">教育经历<span class="tips">注：教育经历从高中起，倒序填写</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('教育经历')">
                            <div v-for="(obj, index) in addForm.education" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <van-cell-group>
                                    <van-field readonly clickable :value="obj.education_1" label="起始年月" placeholder="请选择" :required="itemIsRequired('education_1')" @click="handleShowMonthPicker('education_1', '起始年月', index)" :rules="[{ sId: 'education_1', eId: 'education_8', idx: index, validator: sDateRangeValidator, message: '请选择起始年月', trigger: 'onChange' }]" v-if="itemIsVisible('education_1')"/>
                                    <van-field readonly clickable :value="obj.education_8" label="截止年月" placeholder="请选择" :required="itemIsRequired('education_1')" @click="handleShowMonthPicker('education_8', '截止年月', index)" :rules="[{ sId: 'education_1', eId: 'education_8', idx: index, validator: eDateRangeValidator, message: '请选择截止年月', trigger: 'onChange' }]" v-if="itemIsVisible('education_1')"/>
                                    <van-field v-model.trim="obj.education_2" label="毕业学校" maxlength="50" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('education_2'), message: '请输入毕业学校' }]" :required="itemIsRequired('education_2')" v-if="itemIsVisible('education_2')"/>
                                    <van-field v-model.trim="obj.education_3" label="专业" maxlength="50" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('education_3'), message: '请输入专业' }]" :required="itemIsRequired('education_3')" v-if="itemIsVisible('education_3')"/>
                                    <van-field readonly clickable :value="obj.education_4" label="学历" placeholder="请选择" :required="itemIsRequired('education_4')" @click="handleShowPicker('education_4', '学历', index)" :rules="[{required: itemIsRequired('education_4'), message: '学历' }]" v-if="itemIsVisible('education_4')"/>
                                    <van-field readonly clickable :value="obj.education_5" label="学位" placeholder="请选择" :required="itemIsRequired('education_5')" @click="handleShowPicker('education_5', '学位', index)" :rules="[{required: itemIsRequired('education_5'), message: '学位' }]" v-if="itemIsVisible('education_5')"/>
                                    <van-field name="education_6" label="教育方式" :rules="[{ required: itemIsRequired('education_6'), message: '请选择教育方式' }]" :required="itemIsRequired('education_6')" v-if="itemIsVisible('education_6')">
                                        <template #input>
                                            <van-radio-group v-model="obj.education_6" direction="horizontal">
                                                <van-radio v-for="obj in education_6Array" :name="obj.name" :key="obj.id">{{obj.name}}</van-radio>
                                            </van-radio-group>
                                        </template>
                                    </van-field>
                                    <van-field v-model.trim="obj.education_7" label="证明人" maxlength="20" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('education_7'), message: '请输入证明人' }]" :required="itemIsRequired('education_7')" v-if="itemIsVisible('education_7')"/>
                                </van-cell-group>
                                <div class="remove-btn"><van-button plain native-type="button" type="danger" size="small" v-if="index > 0" @click="removeEducation(index)">- 移除新增项</van-button></div>
                            </div>
                            <div class="apply-job-section-btn">
                                <van-button plain native-type="button" type="primary" size="small" @click="addMoreEducation">+ 添加更多</van-button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('工作经历')">工作经历<span class="tips">注：工作经历不含实习经历，倒序填写</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('工作经历')">
                            <div v-for="(obj, index) in addForm.work" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <van-cell-group>
                                    <van-field readonly clickable :value="obj.work_1" label="起始年月" placeholder="请选择" :required="itemIsRequired('work_1')" @click="handleShowMonthPicker('work_1', '起始年月', index)" :rules="[{ sId: 'work_1', eId: 'work_8', idx: index, validator: sDateRangeValidator, message: '请选择起始年月', trigger: 'onChange' }]" v-if="itemIsVisible('work_1')"/>
                                    <van-field readonly clickable :value="obj.work_8" label="截止年月" placeholder="请选择" :required="itemIsRequired('work_1')" @click="handleShowMonthPicker('work_8', '截止年月', index)" :rules="[{ sId: 'work_1', eId: 'work_8', idx: index, validator: eDateRangeValidator, message: '请选择截止年月', trigger: 'onChange' }]" v-if="itemIsVisible('work_1')"/>
                                    <van-field v-model.trim="obj.work_2" label="工作单位" maxlength="50" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('work_2'), message: '请输入工作单位' }]" :required="itemIsRequired('work_2')" v-if="itemIsVisible('work_2')"/>
                                    <van-field readonly clickable :value="obj.work_3" label="单位性质" placeholder="请选择" :required="itemIsRequired('work_3')" @click="handleShowPicker('work_3', '单位性质', index)" :rules="[{required: itemIsRequired('work_3'), message: '单位性质' }]" v-if="itemIsVisible('work_3')"/>
                                    <van-field v-model.trim="obj.work_4" label="职务/职位" maxlength="50" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('work_4'), message: '请输入职务/职位' }]" :required="itemIsRequired('work_4')" v-if="itemIsVisible('work_4')"/>
                                    <van-field v-model.trim="obj.work_5" label="证明人" maxlength="20" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('work_5'), message: '请输入证明人' }]" :required="itemIsRequired('work_5')" v-if="itemIsVisible('work_5')"/>
                                    <van-field v-model.trim="obj.work_6" label="联系电话" maxlength="20" placeholder="请输入" show-word-limit :rules="[{ id: 'work_6', validator: contactNumberValidator, message: '请输入联系电话', trigger: 'onBlur' }]" :required="itemIsRequired('work_6')" v-if="itemIsVisible('work_6')"/>
                                    <van-field name="work_7" label="工作内容及业绩" :rules="[{ id: 'work_7', idx: index, validator: multiEditorContentValidator, message: '请输入工作内容及业绩' }]" :required="itemIsRequired('work_7')" v-if="itemIsVisible('work_7')">
                                        <template #input>
                                            <quill-editor v-model="obj.work_7" :options="editorOption"></quill-editor>
                                        </template>
                                    </van-field>
                                    <van-field name="work_9" label="离职原因" :rules="[{ id: 'work_9', idx: index, validator: multiEditorContentValidator, message: '请输入离职原因' }]" :required="itemIsRequired('work_9')" v-if="itemIsVisible('work_9')">
                                        <template #input>
                                            <quill-editor v-model="obj.work_9" :options="editorOption"></quill-editor>
                                        </template>
                                    </van-field>
                                </van-cell-group>
                                <div class="remove-btn"><van-button plain native-type="button" type="danger" size="small" v-if="index > 0" @click="removeWork(index)">- 移除新增项</van-button></div>
                            </div>
                            <div class="apply-job-section-btn">
                                <van-button plain native-type="button" type="primary" size="small" @click="addMoreWork">+ 添加更多</van-button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('项目介绍')">项目经验</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('项目介绍')">
                            <div v-for="(obj, index) in addForm.project" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <van-cell-group>
                                    <van-field v-model.trim="obj.project_1" label="项目名称" maxlength="50" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('project_1'), message: '请输入项目名称' }]" :required="itemIsRequired('project_1')" v-if="itemIsVisible('project_1')"/>
                                    <van-field name="project_2" label="项目描述" :rules="[{ id: 'project_2', idx: index, validator: multiEditorContentValidator, message: '请输入项目描述' }]" :required="itemIsRequired('project_2')" v-if="itemIsVisible('project_2')">
                                        <template #input>
                                            <quill-editor v-model="obj.project_2" :options="editorOption"></quill-editor>
                                        </template>
                                    </van-field>
                                    <van-field name="project_3" label="项目职责" :rules="[{ id: 'project_3', idx: index, validator: multiEditorContentValidator, message: '请输入项目职责' }]" :required="itemIsRequired('project_3')" v-if="itemIsVisible('project_3')">
                                        <template #input>
                                            <quill-editor v-model="obj.project_3" :options="editorOption"></quill-editor>
                                        </template>
                                    </van-field>
                                    <van-field name="project_4" label="项目业绩" :rules="[{ id: 'project_4', idx: index, validator: multiEditorContentValidator, message: '请输入项目业绩' }]" :required="itemIsRequired('project_4')" v-if="itemIsVisible('project_4')">
                                        <template #input>
                                            <quill-editor v-model="obj.project_4" :options="editorOption"></quill-editor>
                                        </template>
                                    </van-field>
                                </van-cell-group>
                                <div class="remove-btn"><van-button plain native-type="button" size="small" v-if="index > 0" @click="removeProject(index)">- 移除新增项</van-button></div>
                            </div>
                            <div class="apply-job-section-btn">
                                <van-button plain native-type="button" type="primary" size="small" @click="addMoreProject">+ 添加更多</van-button>
                            </div>
                            <div class="apply-job-section-block divider-line no-hover" v-if="itemIsVisible('project_5')">
                                <van-field name="project_5" label="重大奖惩情况" :rules="[{ id: 'project_5', validator: editorContentValidator, message: '请输入重大奖惩情况', trigger: 'onBlur' }]" :required="itemIsRequired('project_5')" v-if="itemIsVisible('project_5')">
                                    <template #input>
                                        <quill-editor v-model="addForm.project_5" :options="editorOption"></quill-editor>
                                    </template>
                                </van-field>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('家庭主要成员')">家庭主要成员<span class="tips">注：填写主要家庭成员</span></div>
                        <div class="apply-job-section" v-if="categoryIsVisible('家庭主要成员')">
                            <div v-for="(obj, index) in addForm.family" :class="index===0 ? 'apply-job-section-default' : 'apply-job-section-block divider-line'" :key="index">
                                <van-cell-group>
                                    <van-field v-model.trim="obj.family_1" label="姓名" maxlength="20" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('family_1'), message: '请输入姓名' }]" :required="itemIsRequired('family_1')" v-if="itemIsVisible('family_1')"/>
                                    <van-field readonly clickable :value="obj.family_2" label="出生年月" placeholder="请选择" :required="itemIsRequired('family_2')" @click="handleShowDatePicker('family_2', '出生年月', index)" :rules="[{required: itemIsRequired('family_2'), message: '请选择出生年月' }]" v-if="itemIsVisible('family_2')"/>
                                    <van-field readonly clickable :value="obj.family_3" label="关系" placeholder="请选择" :required="itemIsRequired('family_3')" @click="handleShowPicker('family_3', '关系', index)" :rules="[{required: itemIsRequired('family_3'), message: '请选择关系' }]" v-if="itemIsVisible('family_3')"/>
                                    <van-field readonly clickable :value="obj.family_4" label="政治面貌" placeholder="请选择" :required="itemIsRequired('family_4')" @click="handleShowPicker('family_4', '政治面貌', index)" :rules="[{required: itemIsRequired('family_4'), message: '请选择政治面貌' }]" v-if="itemIsVisible('family_4')"/>
                                    <van-field v-model.trim="obj.family_5" label="工作单位及职务" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: itemIsRequired('family_5'), message: '请输入工作单位及职务' }]" :required="itemIsRequired('family_5')" v-if="itemIsVisible('family_5')"/>
                                    <van-field v-model.trim="obj.family_6" label="联系电话" maxlength="20" placeholder="请输入" show-word-limit :rules="[{ id: 'family_6', validator: contactNumberValidator, message: '请输入联系电话', trigger: 'onBlur' }]" :required="itemIsRequired('family_6')" v-if="itemIsVisible('family_6')"/>
                                </van-cell-group>
                                <div class="remove-btn"><van-button plain native-type="button" type="danger" size="small" v-if="index > 0" @click="removeFamily(index)">- 移除新增项</van-button></div>
                            </div>
                            <div class="apply-job-section-btn">
                                <van-button plain native-type="button" type="primary" size="small" @click="addMoreFamily">+ 添加更多</van-button>
                            </div>
                        </div>

                        <div class="divider" v-if="categoryIsVisible('其他问题')">其他问题</div>
                        <div class="apply-job-section" v-if="categoryIsVisible('其他问题')">
                            <van-cell-group>
                                <van-cell title="问题描述">
                                    <template>
                                        <div class="multiline-content" v-html="resumeFields.find(item => item.id === 'others_1')?.otherDesc"></div>
                                    </template>
                                </van-cell>
                                <van-field name="others_1" label="问题回复" :rules="[{ id: 'others_1', validator: editorContentValidator, message: '请输入问题回复', trigger: 'onBlur' }]" :required="itemIsRequired('others_1')" v-if="itemIsVisible('others_1')">
                                    <template #input>
                                        <quill-editor v-model="addForm.others_1" :options="editorOption"></quill-editor>
                                    </template>
                                </van-field>
                            </van-cell-group>
                        </div>

                        <div class="divider" v-if="itemIsVisible('condition_1')">当前情况<span class="tips">注：填写当前劳动合同情况</span></div>
                        <div class="apply-job-section" v-if="itemIsVisible('condition_1')">
                            <div class="apply-job-section-default">
                                <van-field readonly clickable :value="addForm.contact_status" label="合同情况" placeholder="请选择" required @click="handleShowContactPicker" :rules="[{required: true, message: '请选择合同情况' }]"/>
                                <van-popup v-model="showContactPicker" position="bottom">
                                    <van-picker show-toolbar title="合同情况" :columns="contact_statusArray" @confirm="onContactPickerConfirm" @cancel="showContactPicker = false"/>
                                </van-popup>
                                <van-field v-model.trim="addForm.contact_memo" label="其他情况" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: true, message: '请输入其他情况' }]" required v-if="showContactMemo"/>
                                <van-field name="contact_inspect" label="允许情况核实" :rules="[{ required: true, message: '请选择是否允许情况核实' }]" required>
                                    <template #input>
                                        <div class="multiline-content">
                                            本人郑重声明，我在表中提供的有关信息真实准确，愿意承担因提供任何虚假与不实信息所造成的一切后果。<br />本人
                                            <van-radio-group v-model="addForm.contact_inspect" direction="horizontal">
                                                <van-radio name="同意">同意</van-radio>
                                                <van-radio name="不同意">不同意</van-radio>
                                            </van-radio-group>
                                            贵单位在必要的情况下对有关信息进行核实。
                                        </div>
                                    </template>
                                </van-field>
                                <van-field name="contact_signature" label="申请人签名" required>
                                    <template #input>
                                        <div class="signature-area">
                                            <vueEsign ref="esign"
                                                      :width="esign.width"
                                                      :height="esign.height"
                                                      :isCrop="esign.isCrop"
                                                      :lineWidth="esign.lineWidth"
                                                      :lineColor="esign.lineColor"
                                                      :bgColor.sync="esign.bgColor"></vueEsign>
                                            <div class="signature-button">
                                                <van-button type="primary" native-type="button" size="mini" @click="handleReset">清空</van-button>
                                                <span class="tips">注意：请按住鼠标左键在上面空白处移动鼠标签写姓名</span>
                                            </div>
                                        </div>
                                    </template>
                                </van-field>
                                <van-field name="contact_recommend" label="个人自荐">
                                    <template #input>
                                        <quill-editor v-model="addForm.contact_recommend" :options="editorOption"></quill-editor>
                                    </template>
                                </van-field>
                            </div>
                        </div>

                        <div class="submit-btn">
                            <van-button type="primary" square block :disabled="isDisabled" native-type="submit">确认提交</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
        <van-dialog v-model="showSuccess" @confirm="goPositionDetail">
            <div class="confirm">
                <div class="icon"><van-icon name="checked" /></div>
                <div class="title">报名成功！</div>
                <div class="content">请留意 消息中心 了解应聘进度</div>
            </div>
        </van-dialog>

        <div class="footer">
        </div>
    </div>
</template>

<script>
// 调用富文本编辑器
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import {getPositionByCode, reqApplyPosition, reqGetAvailsPerApplicant, reqUploadSignature} from "../../api/client-api";
import {reqDeleteFile, reqDictionaryMapByCodeList} from "../../api/user-api";
import {
    check18IdNumber, checkContactNumber,
    checkEditorContent,
    checkEmail,
    checkMobile,
    convertBase64UrlToBlob
} from "../../assets/js/utils";
import county from "../../assets/area/county";
import MultiplePicker from "../../components/h5/MultiplePicker";
import vueEsign from 'vue-esign';

export default {
    components: {MultiplePicker, quillEditor, vueEsign},
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            isDisabled: false,
            projectCode: '',
            positionCode: '',
            backTo: '',
            companyName: '',
            projectName: '',
            header: {},
            resumeFields: [],
            pickerKey: '',
            pickerTitle: '',
            pickerIndex: -1,
            showPicker: false,
            datePickerKey: '',
            datePickerTitle: '',
            showDatePicker: false,
            minDate: new Date((new Date().getFullYear() - 60), 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            showMonthPicker: false,
            showCountyPicker: false,
            countyPickerValue: null,
            countyPickerKey: null,
            countyPickerTitle: null,
            countyFieldNames: {
                text: 'label',
                value: 'id',
                children: 'children',
            },
            countyOpts: county,
            pickerColumns: [],
            showContactPicker: false,
            showContactMemo: false,
            showSuccess: false,
            contact_statusArray: ['目前与其他单位不存在劳动关系', '目前与原单位尚未解除劳动关系，但承诺在到岗时，可以提供解除劳动关系证明材料', '其他'],
            position_1Array: [],
            person_7Array: [],
            person_8Array: [],
            person_9Array: [],
            person_13Array: [],
            person_14Array: [],
            person_16Array: [],
            person_17Array: [],
            language_1Array: [],
            language_2Array: [],
            education_4Array: [],
            education_5Array: [],
            education_6Array: [],
            work_3Array: [],
            family_3Array: [],
            family_4Array: [],
            fileList: [],
            esign: {
                width: 400,
                height: 200,
                isCrop: true,
                lineWidth: 4,
                lineColor: '#000000',
                bgColor: ''
            },
            addForm: {
                keywords: [],
                positionId: null,
                positionName: null,
                deptName: null,
                position_1: null,
                person_1: null,
                person_2: null,
                person_4: '男',
                person_6: null,
                person_8: [],
                person_9: null,
                person_10: null,
                person_11: null,
                person_12: null,
                person_13: null,
                person_14: null,
                person_15: null,
                person_16: null,
                person_17: null,
                person_19: null,
                person_28: null,
                person_29: null,
                person_31: '是',
                language: [
                    {language_1: null, language_2: null}
                ],
                education: [
                    {education_1: null, education_2: null, education_3: null, education_4: null, education_5: null, education_6: '统招', education_7: null, education_8: null}
                ],
                work: [
                    {work_1: null, work_2: null, work_3: null, work_4: null, work_5: null, work_6: null, work_7: null, work_8: null, work_9: null}
                ],
                project: [
                    {project_1: null, project_2: null, project_3: null, project_4: null}
                ],
                project_5: null,
                family: [
                    {family_1: null, family_2: null, family_3: null, family_4: null, family_5: null, family_6: null}
                ],
                others_1: null,
                contact_status: null,
                contact_memo: null,
                contact_inspect: '同意',
                contact_signature: null,
                contact_recommend: null,
            },

            uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
            value: '',
            radio: '1',
            showArea: false,
            areaList: {}, // 数据格式见 Area 组件文档
        }
    },
    methods: {
        handleGoBack() {
            let myId = (this.backTo === 'position') ? this.positionCode : this.projectCode;
            this.$router.push({name: this.backTo, params:{id: myId}, query: {tab: 'job-list'}});
        },
        validateAndRedirect() {
            reqGetAvailsPerApplicant({projectCode: this.projectCode, positionCode: this.positionCode}).then((res)=>{
                if (res.data.data) {
                    if (res.data.data.availsPerPosition <= 0) {
                        this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                    } else if (res.data.data.availsPerProject <= 0) {
                        this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                    } else {
                        this.getPosition();
                        this.getDictionaryMapByCodeList();
                    }
                } else {
                    this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
                }
            }).catch((err)=>{
                console.log('err===', err);
                this.$router.push({name: 'applicationPrompt', query: {projectCode: this.projectCode}});
            })
        },
        getPosition() {
            getPositionByCode({code: this.positionCode}).then((res)=>{
                if (res.data.data) {
                    this.companyName = res.data.data.companyName ? res.data.data.companyName : '';
                    this.projectName = res.data.data.projectName ? res.data.data.projectName : '';
                    this.resumeFields = JSON.parse(res.data.data.resumeFields);
                    this.addForm.positionId = res.data.data.id;
                    this.addForm.positionName = res.data.data.name;
                    this.addForm.deptName = res.data.data.deptName;
                }
            }).catch((err)=>{
                this.resumeFields = null;
                console.log('err===', err);
            })
        },
        getDictionaryMapByCodeList() {
            let codes = ['information_source', 'nation', 'job_title', 'politics_status', 'health_condition',
                'marital_status', 'highest_degree', 'language', 'level', 'degree', 'nature_of_business',
                'educational_mode', 'relationship'];
            reqDictionaryMapByCodeList(codes).then((res) => {
                this.position_1Array = res.data.data.information_source;
                this.person_7Array = res.data.data.nation;
                this.person_8Array = res.data.data.job_title;
                this.person_9Array = res.data.data.politics_status;
                this.family_4Array = res.data.data.politics_status;
                this.person_13Array = res.data.data.health_condition;
                this.person_14Array = res.data.data.marital_status;
                this.person_16Array = res.data.data.highest_degree;
                this.person_17Array = res.data.data.highest_degree;
                this.education_4Array = res.data.data.highest_degree;
                this.language_1Array = res.data.data.language;
                this.language_2Array = res.data.data.level;
                this.education_5Array = res.data.data.degree;
                this.work_3Array = res.data.data.nature_of_business;
                this.education_6Array = res.data.data.educational_mode;
                this.family_3Array = res.data.data.relationship;
            }).catch(() => {
                this.position_1Array = [];
                this.person_7Array = [];
                this.person_8Array = [];
                this.person_9Array = [];
                this.family_4Array = [];
                this.person_13Array = [];
                this.person_14Array = [];
                this.person_16Array = [];
                this.person_17Array = [];
                this.education_4Array = [];
                this.language_1Array = [];
                this.language_2Array = [];
                this.education_5Array = [];
                this.work_3Array = [];
                this.education_6Array = [];
                this.family_3Array = [];
            })
        },
        handleShowPicker(key, title, index) {
            this.pickerColumns = this[key+'Array'].map(item => item.name);
            this.pickerKey = key || '';
            this.pickerTitle = title || '';
            this.pickerIndex = (index >= 0 ? index : -1);
            this.showPicker = true;
        },
        onPickerConfirm(val) {
            if (this.pickerKey === 'person_10' && val !== '中共党员') {
                this.addForm.person_10 = null;
            }

            let pickerKey = this.$refs.pickerRef.valueKey + '';
            if (this.pickerIndex >= 0) {
                let name = pickerKey.split('_')[0];
                let obj = this.addForm[name];
                obj[this.pickerIndex][pickerKey] = val;
            } else {
                this.addForm[pickerKey] = val;
            }
            this.showPicker = false;
        },
        //上传文件大小
        handExceeded() {
            this.$toast.fail('文件内容不得超过5M');
        },
        handleRemove() {
            reqDeleteFile([this.addForm.person_1]).then((res) => {
                if (res.data.data && res.data.data.length > 0) {
                    this.addForm.person_1 = null;
                } else {
                    this.$toast.fail('照片删除失败');
                }
            })
        },
        //上传成功
        handleSuccess(file, detail) {
            this.addForm.person_1 = null;
            const formData = new FormData();
            formData.append("file", file.file);
            reqUploadSignature(formData).then((res) => {
                if (res.data.code === 1) {
                    this.addForm.person_1 = res.data.data.currentName;
                } else {
                    this.$toast.fail('照片上传失败');
                }
            })
        },
        handleShowDatePicker(key, title, index) {
            this.datePickerKey = key || '';
            this.datePickerTitle = title || '';
            this.pickerIndex = (index >= 0 ? index : -1);
            if (key === 'person_30') {
                this.minDate = new Date();
                this.maxDate = new Date((new Date().getFullYear() + 20), 11, 31);
            } else {
                this.minDate = new Date((new Date().getFullYear() - 60), 0, 1);
                this.maxDate = new Date();
            }
            this.showDatePicker = true;
        },
        onDatePickerConfirm(val) {
            if (this.pickerIndex >= 0) {
                let name = this.datePickerKey.split('_')[0];
                let obj = this.addForm[name];
                obj[this.pickerIndex][this.datePickerKey] = (val ? this.$moment(val).format('YYYY-MM-DD') : null);
            } else {
                this.addForm[this.datePickerKey] = (val ? this.$moment(val).format('YYYY-MM-DD') : null);
            }
            this.showDatePicker = false;
        },
        handleShowMonthPicker(key, title, index) {
            this.datePickerKey = key || '';
            this.datePickerTitle = title || '';
            this.pickerIndex = (index >= 0 ? index : -1);
            this.minDate = new Date((new Date().getFullYear() - 60), 0, 1);
            this.maxDate = new Date();
            this.showMonthPicker = true;
        },
        onMonthPickerConfirm(val) {
            let name = this.datePickerKey.split('_')[0];
            let obj = this.addForm[name];
            obj[this.pickerIndex][this.datePickerKey] = (val ? this.$moment(val).format('YYYY-MM') : null);
            this.showMonthPicker = false;
        },
        handleShowCountyPicker(key, title) {
            this.countyPickerKey = key || '';
            this.countyPickerTitle = title || '';
            this.showCountyPicker = true;
        },
        onCountyPickerFinish({ selectedOptions }) {
            this.addForm[this.countyPickerKey] = selectedOptions.map((option) => option.label).join('');
            this.showCountyPicker = false;
        },
        emailValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请输入邮箱';
                    return false;
                } else if (!checkEmail(value)) {
                    rule.message = '请输入正确的邮箱';
                    return false;
                }
            } else {
                if (value && !checkEmail(value)) {
                    rule.message = '请输入正确的邮箱';
                    return false;
                }
            }
            return true;
        },
        editorContentValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }

            let val = this.addForm[rule.id];
            let maxLen = obj.maxLength ? obj.maxLength : 500;
            let label = (rule.id === 'others_1' ? '问题回复' : obj.name);
            if (obj.isRequired) {
                if (!val) {
                    rule.message = '请输入' + label;
                    return false;
                } else if (!checkEditorContent(val, maxLen)) {
                    rule.message = label + '不能超过' + maxLen + '字符';
                    return false;
                }
            } else {
                if (val && !checkEditorContent(val, maxLen)) {
                    rule.message = label + '不能超过' + maxLen + '字符';
                    return false;
                }
            }
            return true;
        },
        mobileValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请输入' + obj.name;
                    return false;
                } else if (!checkMobile(value)) {
                    rule.message = '请输入正确的' + obj.name;
                    return false;
                }
            } else {
                if (value && !checkMobile(value)) {
                    rule.message = '请输入正确的' + obj.name;
                    return false;
                }
            }
            return true;
        },
        idNumberValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请输入身份证号码';
                    return false;
                } else if (!check18IdNumber(value)) {
                    rule.message = '请输入正确的身份证号码';
                    return false;
                }
            } else {
                if (value && !check18IdNumber(value)) {
                    rule.message = '请输入正确的身份证号码';
                    return false;
                }
            }
            return true;
        },
        removeLanguage(idx) {
            this.addForm.language.splice(idx, 1);
        },
        addMoreLanguage() {
            this.addForm.language.push({language_1: null, language_2: null});
        },
        sDateRangeValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.sId);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请选择起始年月';
                    return false;
                }

                let name = rule.sId.split('_')[0];
                let obj = this.addForm[name];
                let endMonth = obj[rule.idx][rule.eId];
                if (endMonth && value >= endMonth) {
                    rule.message = '起始年月不能大于截止年月';
                    return false;
                }
            }
            return true;
        },
        eDateRangeValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.sId);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请选择截止年月';
                    return false;
                }

                let name = rule.sId.split('_')[0];
                let obj = this.addForm[name];
                let startMonth = obj[rule.idx][rule.sId];
                if (startMonth && value <= startMonth) {
                    rule.message = '截止年月不能小于起始年月';
                    return false;
                }
            }
            return true;
        },
        removeEducation(idx) {
            this.addForm.education.splice(idx, 1);
        },
        addMoreEducation() {
            this.addForm.education.push({
                education_1: null,
                education_2: null,
                education_3: null,
                education_4: null,
                education_5: null,
                education_6: '统招',
                education_7: null,
                education_8: null
            });
        },
        contactNumberValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }
            if (obj.isRequired) {
                if (!value) {
                    rule.message = '请输入联系电话';
                    return false;
                } else if (!checkContactNumber(value)) {
                    rule.message = '请输入正确的联系电话';
                    return false;
                }
            } else {
                if (value && !checkContactNumber(value)) {
                    rule.message = '请输入正确的联系电话';
                    return false;
                }
            }
            return true;
        },
        multiEditorContentValidator(value, rule) {
            let obj = this.resumeFields?.find(item => item.id === rule.id);
            if (!obj || !obj.isVisible) {
                return true;
            }

            let maxLen = obj.maxLength ? obj.maxLength : 500;
            let name = rule.id.split('_')[0];
            let item = this.addForm[name];
            let val = item[rule.idx][rule.id];

            if (obj.isRequired) {
                if (!val) {
                    rule.message = '请输入' + obj.name;
                    return false;
                } else if (!checkEditorContent(val, maxLen)) {
                    rule.message = obj.name + '不能超过' + maxLen + '字符';
                    return false;
                }
            } else {
                if (val && !checkEditorContent(val, maxLen)) {
                    rule.message = obj.name + '不能超过' + maxLen + '字符';
                    return false;
                }
            }
            return true;
        },
        removeWork(idx) {
            this.addForm.work.splice(idx, 1);
        },
        addMoreWork() {
            this.addForm.work.push({
                work_1: null,
                work_2: null,
                work_3: null,
                work_4: null,
                work_5: null,
                work_6: null,
                work_7: null,
                work_8: null,
                work_9: null
            });
        },
        removeProject(idx) {
            this.addForm.project.splice(idx, 1);
        },
        addMoreProject() {
            this.addForm.project.push({project_1: null, project_2: null, project_3: null, project_4: null});
        },
        removeFamily(idx) {
            this.addForm.family.splice(idx, 1);
        },
        addMoreFamily() {
            this.addForm.family.push({
                family_1: null,
                family_2: null,
                family_3: null,
                family_4: null,
                family_5: null,
                family_6: null
            });
        },
        handleShowContactPicker() {
            this.showContactPicker = true;
        },
        onContactPickerConfirm(val) {
            this.addForm.contact_status = val;
            if (val === '其他') {
                this.showContactMemo = true;
            } else {
                this.showContactMemo = false;
                this.addForm.contact_memo = null;
            }
            this.showContactPicker = false;
        },
        handleReset() {
            this.$refs.esign.reset();
        },
        categoryIsVisible(categoryName) {
            let idx = this.resumeFields?.filter(item => item.category === categoryName).findIndex(item => item.isVisible === true);
            return (idx >= 0);
        },
        itemIsVisible(itemId) {
            let obj = this.resumeFields?.find(item => item.id === itemId);
            return (obj && obj.isVisible);
        },
        itemIsRequired(itemId) {
            let obj = this.resumeFields?.find(item => item.id === itemId);
            return (obj && obj.isRequired);
        },
        createResume(now) {
            this.addForm.keywords = [this.addForm.positionName];
            let resume = [{
                "name": "职位信息",
                "children": [
                    {"name": "投递时间", "value": now},
                    {"name": "应聘职位", "value": this.addForm.positionName},
                    {"name": "职位URL", "value": window.location.protocol + "//" + window.location.host + "/position/" + this.positionCode},
                    {"name": "应聘部门", "value": this.addForm.deptName},
                    {"name": "应聘项目", "value": this.projectName},
                    {"name": "应聘公司", "value": this.companyName}
                ]
            }];
            this.resumeFields?.forEach(field => {
                if (field.isVisible) {
                    let category = resume.find(item => item.name === field.category);
                    if (!category) {
                        if (field.category === '当前情况') {
                            category = {"name": field.category, "value": ''};
                        } else {
                            category = {"name": field.category, "children": []};
                        }
                        resume.push(category);
                    }

                    let obj = this[field.id + 'Array']?.find(item => item.id === this.addForm[field.id]);
                    if (field.category === '职位信息') {
                        let val = obj ? obj.name : this.addForm[field.id];
                        category.children = [{"name": field.name, "value": val}, ...category.children];
                    } else if (field.category === '其他问题') {
                        let name = this.resumeFields.find(item => item.id === 'others_1')?.otherDesc;
                        category.children = [{"name": name, "value": this.addForm[field.id]}, ...category.children];
                    } else if (field.category === '个人资料') {
                        let val = obj ? obj.name : this.addForm[field.id];
                        if (field.id === 'person_5' || field.id === 'person_10' || field.id === 'person_15' || field.id === 'person_30') {
                            val = this.addForm[field.id] ? this.$moment(this.addForm[field.id]).format('YYYY-MM-DD') : null;
                        }
                        if (field.id === 'person_8') {
                            let labelArray = [];
                            this.addForm[field.id]?.forEach(id => {
                                labelArray.push(this[field.id+'Array'].find(f => f.id === id).name);
                            });
                            val = labelArray.join('，');
                        }

                        if (!(field.id === 'person_10' && this.addForm.person_9 !== '中共党员')) {
                            category.children.push({"name": field.name, "value": val});
                        }
                        if ((field.id === 'person_2' || field.id === 'person_23') && val) { //姓名、手机号码
                            this.addForm.keywords.push(val);
                        }
                    } else if (field.category === '当前情况') {
                        let content =
                            "<div>" +
                            "   <div>" +
                            "       <p>申请人劳动合同的情况（从下面中选一项）：</p>" +
                            "       <ul>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '目前与其他单位不存在劳动关系' ? "checked" : "") + ">目前与其他单位不存在劳动关系</li>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '目前与原单位尚未解除劳动关系，但承诺在到岗时，可以提供解除劳动关系证明材料' ? "checked" : "") + ">目前与原单位尚未解除劳动关系，但承诺在到岗时，可以提供解除劳动关系证明材料</li>" +
                            "           <li><input type='checkbox' disabled " + (this.addForm.contact_status === '其他' ? "checked" : "") + ">其他：" + (this.addForm.contact_status === '3' ? this.addForm.contact_memo : "") + "</li>" +
                            "       </ul>" +
                            "   </div>" +
                            "   <p>本人郑重声明，我在表中提供的有关信息真实准确，愿意承担因提供任何虚假与不实信息所造成的一切后果。</p>" +
                            "   <p>本人<input type='checkbox' disabled " + (this.addForm.contact_inspect === '同意' ? "checked" : "") + ">同意<input type='checkbox' disabled " + (this.addForm.contact_inspect === '不同意' ? "checked" : "") + ">不同意  贵单位在必要的情况下对有关信息进行核实。</p>" +
                            "   <div class='sign'>" +
                            "       <div class='person'>申请人：<img src='${downloadFileURL}" + this.addForm.contact_signature + "/'></img></div>" +
                            "       <div class='date'>申请时间：" + now + "</div>" +
                            "       <div class='recommend'>" +
                            "           <label>个人自荐:</label>" +
                            "           <div class='multiline-content'>" + (this.addForm.contact_recommend ? this.addForm.contact_recommend : "") + "</div>" +
                            "       </div>" +
                            "   </div>" +
                            "</div>";
                        category.value = content;
                    } else if (field.id === 'project_5') {
                        category[field.id] = {"name": field.name, "value": this.addForm[field.id]};
                    } else if (field.category === '外语水平' || field.category === '教育经历'
                        || field.category === '工作经历' || field.category === '项目介绍' || field.category === '家庭主要成员') {
                        let _index = (field.id.indexOf('_') >= 0 ? field.id.indexOf('_') : field.id.length);
                        let _id = field.id.substring(0, _index);
                        let _objArray = this.addForm[_id];
                        for (let i in _objArray) {
                            let _obj = _objArray[i];
                            let _opts = this[field.id + 'Array']?.find(item => item.id === _obj[field.id]);
                            let _objVal = _opts ? _opts.name : _obj[field.id];
                            if (field.id === 'education_1') {
                                _objVal = _obj['education_1'] ? this.$moment(_obj['education_1']).format('YYYY-MM') + ' 至 ' : 'YYYY-MM 至 ';
                                _objVal += _obj['education_8'] ? this.$moment(_obj['education_8']).format('YYYY-MM') : 'YYYY-MM';
                            }
                            if (field.id === 'work_1') {
                                _objVal = _obj['work_1'] ? this.$moment(_obj['work_1']).format('YYYY-MM') + ' 至 ' : 'YYYY-MM 至 ';
                                _objVal += _obj['work_8'] ? this.$moment(_obj['work_8']).format('YYYY-MM') : 'YYYY-MM';
                            }
                            if (field.id === 'family_2') {
                                _objVal = _obj['family_2'] ? this.$moment(_obj['family_2']).format('YYYY-MM-DD') : null;
                            }
                            let child = category.children[i] ? category.children[i] : {};
                            category.children[i] = Object.assign({}, child, {[field.id]: field.name, [field.id+'value']: _objVal});
                        }
                    }
                }
            });

            return resume;
        },
        handleSubmit() {
            let that = this;
            that.isDisabled = true;
            // 是否需要上传签名
            let isConditionVisible = this.resumeFields?.find(item => item.id === 'condition_1')?.isVisible;
            if (isConditionVisible) {
                that.$refs.esign.generate().then(res => {
                    that.addForm.contact_signature = null;
                    const formData = new FormData();
                    formData.append("file", convertBase64UrlToBlob(res), Date.now() + '.jpeg'); // 转换成Blob上传
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            that.addForm.contact_signature = res.data.data.currentName;
                            let now = that.$moment().format('YYYY-MM-DD HH:mm:ss');
                            let myResume = that.createResume(now);
                            let params = {
                                positionId: this.addForm.positionId,
                                keywords: this.addForm.keywords.join('-'),
                                resume: JSON.stringify(myResume),
                                submitTime: now
                            }
                            reqApplyPosition(params).then((res) => {
                                if (res.data.code === 1) {
                                    that.showSuccess = true;
                                } else {
                                    that.isDisabled = false;
                                    that.$toast.fail('添加失败');
                                }
                            }).catch(() => {
                                that.isDisabled = false;
                                that.$toast.fail('添加失败');
                            })
                        } else {
                            that.$refs.addForm.validate('contact_signature');
                            that.isDisabled = false;
                        }
                    }).catch((err) => {
                        that.$refs.addForm.validate('contact_signature');
                        that.isDisabled = false;
                    });
                }).catch(err => {
                    // 画布没有签字时会执行这里 'Not Signned'
                    that.addForm.contact_signature = null;
                    that.$refs.addForm.validate('contact_signature');
                    that.isDisabled = false;
                })
            } else {
                let now = that.$moment().format('YYYY-MM-DD HH:mm:ss');
                let myResume = that.createResume(now);
                let params = {
                    positionId: this.addForm.positionId,
                    keywords: this.addForm.keywords.join('-'),
                    resume: JSON.stringify(myResume),
                    submitTime: now
                }
                reqApplyPosition(params).then((res) => {
                    if (res.data.code === 1) {
                        that.showSuccess = true;
                    } else {
                        that.isDisabled = false;
                        that.$toast.fail('添加失败');
                    }
                }).catch(() => {
                    that.isDisabled = false;
                    that.$toast.fail('添加失败');
                })
            }
        },
        goPositionDetail() {
            this.showSuccess = false;
            this.isDisabled = false;
            this.$router.push('/position/' + this.positionCode);
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization');
        this.header = {Authorization: token};
        this.projectCode = this.$route.query.projectCode;
        this.positionCode = this.$route.query.positionCode;
        this.backTo = this.$route.query.backTo;
        this.validateAndRedirect();
    }
};
</script>

<style scoped>

</style>
